<template>
  <v-card class="login-form">
    <v-container class="pa-4">
      <h4 class="headline text-center mb-3 font-weight-light text-capitalize">
        {{ $t('auth.login') }}
      </h4>
      <login-form></login-form>
    </v-container>
  </v-card>
</template>

<script>
import LoginForm from './LoginForm';

export default {
  name: 'login-panel',
  components: {
    LoginForm
  }
};
</script>
