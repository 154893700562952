<template>
  <v-form ref="loginForm" @submit.prevent="login">
    <v-text-field
      solo
      v-model="user.email"
      :rules="[rules.required, rules.email]"
      :label="$t('common.email')"
      prepend-inner-icon="mdi-account"
    ></v-text-field>
    <v-text-field
      solo
      v-model="user.password"
      :rules="[rules.required]"
      type="password"
      :label="$t('common.password')"
      prepend-inner-icon="mdi-lock"
    ></v-text-field>
    <v-card-actions class="pa-0">
      <v-btn dark block color="primary" type="submit">
        {{ $t('auth.login') }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import validators from '../../../core/validators';
import { LOGIN } from '../store/types';
import userRoles from '../../../enums/user.roles';

export default {
  name: 'login-form',
  data() {
    return {
      user: {
        email: '',
        password: ''
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        email: validators.isEmail(this.$i18n.t('common.invalidEmail'))
      }
    };
  },
  methods: {
    async login() {
      if (!this.$refs.loginForm.validate()) {
        return;
      }

      const result = await this.$store.dispatch(LOGIN, this.user);
      this.$i18n.locale = result.user.lang;

      if (result.user.role === userRoles.moderator) {
        this.$router.push({ path: '/tasks' });
      } else {
        this.$router.push({ path: '/users' });
      }
    }
  }
};
</script>
