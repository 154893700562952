<template>
  <v-container fluid>
    <v-row>
      <v-col offset-md="4" md="4" sm="12">
        <login-panel></login-panel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginPanel from '../../auth/components/LoginPanel';

export default {
  name: 'home',
  components: {
    LoginPanel
  }
};
</script>
